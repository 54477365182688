.App{
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #F0C27B;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #4B1248, #F0C27B);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #4B1248, #F0C27B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-repeat: repeat-y;

}

#videoContainer{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  top: .5rem;
}

#videoCanvas {
  position: absolute;
}

#video{
  position: absolute;
}